export const config = {
  serverURL: "https://production.api.restaron.kitg.com.ua/",
  API: "https://production.api.restaron.kitg.com.ua/admin/api",
  ws: "wss://production.api.restaron.kitg.com.ua",
  imageProxy: "https://production.api.restaron.kitg.com.ua/",
  editorKey: "h05vqgifi1kg8rkngk5ocb8p9az0p5tfn8nzchrbsp7mvx9l",
};

// dev
// export const config = {
//   serverURL: "https://dev.api.restaron.kitglobal.com.ua/",
//   API: "https://dev.api.restaron.kitglobal.com.ua/admin/api",
//   ws: "wss://dev-api.restaron.kitg.com.ua",
//   imageProxy: "https://dev.api.restaron.kitglobal.com.ua/",
//   editorKey: "h05vqgifi1kg8rkngk5ocb8p9az0p5tfn8nzchrbsp7mvx9l",
// };
