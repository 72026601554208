import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import * as Contexts from "../contexts";

import * as Auth from "../pages/Auth";
import * as Home from "../pages/Home";
import * as Users from "../pages/Users";
import * as UsersBirthdays from "../pages/UsersBirthdays";
import * as Roles from "../pages/Roles";
import * as Staff from "../pages/Staff";
import * as Orders from "../pages/Orders";
import * as Errors from "../pages/Errors";
import * as Archive from "../pages/Archive";
import * as Products from "../pages/Products";
import * as Restaurants from "../pages/Restaurants";
import * as Settings from "../pages/Settings";
import * as Categories from "../pages/Categories";
import * as Stocks from "../pages/Stocks";
import * as Delivery from "../pages/Delivery";
import * as Booking from "../pages/Booking";
import * as OFP from "../pages/OptionsForProducts";
import { LendingBaboGardens } from "../pages/LendingBaboGardens";
import { LendingMonsPius } from "../pages/LendingMonsPius";
import { LendingParaDjanov } from "../pages/LendingParaDjanov";
import { LendingLibraria } from "../pages/LendingLibraria";
import {
  BannersCreatePage,
  BannersDetailPage,
  BannersIndexPage,
} from "../pages/Banners";
import { SeoPage } from "../pages/SEO";

const Router = () => {
  const { access } = useContext(Contexts.UserContext);
  const { isAuthenticated } = useContext(Contexts.AuthContext);

  if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/admin" exact>
          {access?.settings && access?.settings.view ? (
            <Home.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/orders" exact>
          {access?.orders && access?.orders?.view ? (
            <Orders.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/orders/:id" exact>
          {access?.orders && access?.orders?.change ? (
            <Orders.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/categories" exact>
          {access?.categories && access?.categories?.view ? (
            <Categories.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/categories/create" exact>
          {access?.categories && access?.categories?.change ? (
            <Categories.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/categories/:id" exact>
          {access?.categories && access?.categories?.change ? (
            <Categories.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/products" exact>
          {access?.products && access?.products?.view ? (
            <Products.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/products/create" exact>
          {access?.products && access?.products?.change ? (
            <Products.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/products/:id" exact>
          {access?.products && access?.products?.change ? (
            <Products.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/restaurants/" exact>
          {access?.restaurants && access?.restaurants?.view ? (
            <Restaurants.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/restaurants/create" exact>
          {access?.restaurants && access?.restaurants?.change ? (
            <Restaurants.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/restaurants/:id" exact>
          {access?.restaurants && access?.restaurants?.change ? (
            <Restaurants.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/booking" exact>
          <Booking.IndexPage />
        </Route>

        <Route path="/booking/create" exact>
          <Booking.CreatePage />
        </Route>

        <Route path="/booking/:id" exact>
          <Booking.DetailPage />
        </Route>

        <Route path="/delivery" exact>
          <Delivery.IndexPage />
        </Route>

        <Route path="/stocks" exact>
          <Stocks.StocksPage />
        </Route>
        <Route path="/stocks/create" exact>
          <Stocks.CreatePage />
        </Route>
        <Route path="/stocks/:id" exact>
          <Stocks.DetailPage />
        </Route>
        <Route path="/users" exact>
          {access?.users && access?.users?.view ? (
            <Users.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/users/:id" exact>
          {access?.users && access?.users?.change ? (
            <Users.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/usersBirthdays" exact>
          {access?.users && access?.users?.view ? (
            <UsersBirthdays.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/staff" exact>
          {access?.staff && access?.staff?.view ? (
            <Staff.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/staff/create" exact>
          {access?.staff && access?.staff?.change ? (
            <Staff.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/staff/:id" exact>
          {access?.staff && access?.staff?.change ? (
            <Staff.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/roles" exact>
          {access?.roles && access?.roles?.view ? (
            <Roles.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/babo-gardens/:id" exact>
          {access?.landings && access?.landings?.view ? (
            <LendingBaboGardens />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/mons-pius/:id" exact>
          {access?.landings && access?.landings?.view ? (
            <LendingMonsPius />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/para-djanov-and-porvein/:id" exact>
          {access?.landings && access?.landings?.view ? (
            <LendingParaDjanov />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/libraria/:id/:concertId" exact>
          {access?.landings && access?.landings?.view ? (
            <LendingLibraria />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>
        <Route path="/cent-aurа/:id" exact>
          {access?.landings && access?.landings?.view ? (
            <LendingParaDjanov />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/roles/create" exact>
          {access?.staff && access?.staff?.change ? (
            <Roles.CreatePage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/roles/:id" exact>
          {access?.staff && access?.staff?.change ? (
            <Roles.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/banners/create" exact>
          <BannersCreatePage />
        </Route>

        <Route path="/banners/:id" exact>
          <BannersDetailPage />
        </Route>

        <Route path="/banners" exact>
          <BannersIndexPage />
        </Route>

        <Route path="/seo" exact>
          <SeoPage />
        </Route>

        <Route path="/archive" exact>
          {access?.ordersHistory && access?.ordersHistory?.view ? (
            <Archive.IndexPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/archive/:id" exact>
          {access?.ordersHistory && access?.ordersHistory?.change ? (
            <Archive.DetailPage />
          ) : (
            <Errors.Forbidden />
          )}
        </Route>

        <Route path="/options-for-products" exact>
          <OFP.OptionsForProducts />
        </Route>

        <Route path="/options-for-products/create" exact>
          <OFP.CreatePage />
        </Route>

        <Route path="/options-for-products/:id" exact>
          <OFP.DetailPage />
        </Route>

        <Route path="/settings" exact>
          <Settings.IndexPage />
        </Route>

        <Redirect to="/admin" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/">
        <Auth.IndexPage />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
};

export default Router;
